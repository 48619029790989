import React from "react";
import { Box, Typography } from "@mui/material";
import theme from "theme";

const HomeContentBox = ({ icon, title, children }) => {
  return (
    <Box
      mt={10}
      sx={{
        [theme.breakpoints.down("md")]: {
          mx: 2,
        },
      }}
    >
      <Box
        display="flex"
        alignItems="center"
        sx={{
          [theme.breakpoints.down("md")]: {
            mx: 2,
          },
        }}
      >
        <Box>{icon && <Box mr={4}>{icon}</Box>}</Box>
        <Typography
          variant="h2"
          color="primary"
          fontWeight={800}
          sx={{
            fontSize: "2.15rem",
            [theme.breakpoints.down("md")]: {
              fontSize: "1.5rem",
              flexBasis: "80%",
            },
          }}
        >
          {title}
        </Typography>
      </Box>
      {children}
    </Box>
  );
};

export default HomeContentBox;
