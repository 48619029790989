import React, { memo } from "react";

import { Box, Typography } from "@mui/material";

import style from "components/Investment/style.module.scss";

function createMarkup(text) {
  // let editText = text.slice(0, 200);
  // if (text.length > 100) editText = editText + "...";

  return { __html: `${text}` };
}

const DefaultElement = ({ data: { description, contact_name } }) => {
  return (
    <Box>
      <Typography variant="body2" color="black" fontWeight={600}>
        Expert {contact_name}
      </Typography>
      <Box mt={3}>
        {description ? (
          <Typography
            style={{ color: "black" }}
            lineHeight="1.25rem"
            textOverflow="ellipsis"
            maxHeight="160px"
            minHeight="160px"
            overflow="hidden"
            dangerouslySetInnerHTML={createMarkup(description)}
          ></Typography>
        ) : (
          <Typography
            textOverflow="ellipsis"
            maxHeight="160px"
            minHeight="160px"
            overflow="hidden"
            style={{ color: "black" }}
            lineHeight="1.25rem"
          >
            Wyjątkowo luksusowy i nowoczesny apartamentowiec stworzony dla
            najbardziej wymagających klientów. Najwyższy standard apartamentowca
            mieszkalnego w Szczecinie na miarę 5-gwiazdkowego hotelu.
          </Typography>
        )}
      </Box>
    </Box>
  );
};

const DisplayElements = memo(
  ({
    props: {
      visible_fields: { contact_name, city, district, description },
    },
  }) => {
    return (
      <Box
        paddingX={5}
        pt={4}
        pb={2}
        width="100%"
        flexGrow={1}
        className={style.textContent}
      >
        <Typography>
          <Box component="span" fontSize={"0.85rem"} color="black">
            Rynek:
            <Typography
              component="span"
              variant="body2"
              fontWeight={800}
              color="secondary"
              fontSize="1em"
            >
              {" "}
              Pierwotny
            </Typography>
          </Box>
        </Typography>

        <Box mt={2}>
          <Typography
            variant="p"
            color={"primary"}
            fontSize="1.25rem"
            fontWeight={800}
          >
            {`${city}${district && `, ${district}`}`}
          </Typography>
        </Box>
        <Box
          display={"flex"}
          flexDirection="row"
          justifyContent="space-between"
        >
          <DefaultElement
            data={{
              contact_name,
              description,
            }}
          />
        </Box>
      </Box>
    );
  }
);

export default DisplayElements;
