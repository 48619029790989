import React, { memo, useEffect, useRef, useState } from "react";
import { Box, Grid, Hidden, Typography } from "@mui/material";
import Investment from "components/Investment";
import style from "./style.module.scss";
import HomeContentBox from "components/HomeContentBox";
import InvestmentBox from "components/InvestmentBox";

const ActualOffers = memo(
  ({
    actualOffers,
    backgroundColor,
    lastOfferPrimaryMarket,
    lastOfferSecondaryMarket,
    investment = false,
  }) => {
    const [activeSlider, setActiveSlider] = useState(1);
    const ref = useRef(null);
    const refItem = useRef(null);

    const onScroll = (e) => {
      const widthContainer = refItem.current.clientWidth - 60;

      if (e.target.scrollLeft < widthContainer) {
        setActiveSlider(1);
      } else if (
        e.target.scrollLeft > widthContainer &&
        e.target.scrollLeft < widthContainer * 2
      ) {
        setActiveSlider(2);
      } else if (
        e.target.scrollLeft > widthContainer * 2 &&
        e.target.scrollLeft < widthContainer * 3
      ) {
        setActiveSlider(3);
      } else {
        setActiveSlider(4);
      }
    };

    const selectSlider = (index) => {
      if (ref.current) {
        ref.current.scroll({
          behavior: "smooth",
          left: (refItem.current.clientWidth + 10) * index,
        });
      }
    };

    if (investment) {
      return (
        <Box>
          <Hidden mdDown>
            <Box mt={6} display="flex">
              {(actualOffers ? actualOffers : [])?.map(
                ({
                  uuid,
                  contact_name,
                  description,
                  marker = null,
                  city,
                  district,
                  investment_pictures,
                }) => (
                  <Box
                    key={"ActualOffers_" + uuid + contact_name}
                    mx={3}
                    width="100%"
                  >
                    <InvestmentBox
                      key={"ActualOffers_" + uuid + contact_name}
                      visible_fields={{
                        uuid,
                        contact_name,
                        description,
                        marker,
                        city,
                        district,
                        investment_pictures,
                      }}
                      backgroundColor={backgroundColor}
                    />
                  </Box>
                )
              )}
            </Box>
          </Hidden>
          <Hidden mdUp>
            <Box
              mt={6}
              display="flex"
              sx={{ overflowX: "scroll" }}
              className={style.sliderInvestments}
              onScroll={onScroll}
              ref={ref}
            >
              {(actualOffers ? actualOffers : [])?.map(
                (
                  {
                    uuid,
                    contact_name,
                    description,
                    marker = null,
                    city,
                    district,
                    investment_pictures,
                  },
                  index
                ) => (
                  <Box
                    key={"ActualOffers_" + uuid + contact_name}
                    width="80vw"
                    mr={5}
                    ref={index === 0 ? refItem : null}
                  >
                    <InvestmentBox
                      key={"ActualOffers_" + uuid + contact_name}
                      visible_fields={{
                        uuid,
                        contact_name,
                        description,
                        marker,
                        city,
                        district,
                        investment_pictures,
                      }}
                      backgroundColor={backgroundColor}
                    />
                  </Box>
                )
              )}
            </Box>
            <Box display="flex" paddingX={20} justifyContent="center" mt={1}>
              {actualOffers?.map((el, index) => {
                return (
                  <Box
                    key={index}
                    color="transparent"
                    width={activeSlider === index + 1 ? "30px" : "20px"}
                    height="5px"
                    mx={2}
                    borderRadius={2}
                    bgcolor={
                      activeSlider === index + 1 ? "#de1f24" : "rgba(0,0,0,0.3)"
                    }
                    onClick={() => selectSlider(index)}
                    sx={{ transition: "0.3s" }}
                  />
                );
              })}
            </Box>
          </Hidden>
        </Box>
      );
    }
    return (
      <Box>
        <Hidden mdDown>
          <Box mt={6} display="flex">
            {(actualOffers ? actualOffers : [])?.map(
              ({
                uuid,
                investment_uuid,
                investment_district,
                number_of_offers,
                price,
                area,
                rooms,
                pricemkw,
                area_to,
                rooms_to,
                name,
                investment_address,
                investment_city,
                files,
                city_name,
                description,
                marker = null,
              }) => (
                <Box key={"ActualOffers_" + uuid + name} mx={3} width="100%">
                  <Investment
                    key={"ActualOffers_" + uuid + name}
                    visible_fields={{
                      offers: number_of_offers,
                      price: price,
                      area_from: area,
                      area_to: area_to,
                      rooms_from: rooms,
                      rooms_to: rooms_to,
                      uuid: uuid,
                      city: city_name ? city_name : investment_city,
                      name: name,
                      investment_city,
                      investment_address: investment_address,
                      pricemkw: pricemkw,
                      investment_district,
                      investment_uuid,
                      files,
                      description,
                      marker,
                    }}
                    backgroundColor={backgroundColor}
                    lastOfferPrimaryMarket={lastOfferPrimaryMarket}
                    lastOfferSecondaryMarket={lastOfferSecondaryMarket}
                  />
                </Box>
              )
            )}
          </Box>
        </Hidden>
        <Hidden mdUp>
          <Box
            mt={6}
            display="flex"
            sx={{ overflowX: "scroll" }}
            className={style.sliderInvestments}
            onScroll={onScroll}
            ref={ref}
          >
            {(actualOffers ? actualOffers : [])?.map(
              (
                {
                  uuid,
                  investment_uuid,
                  investment_district,
                  number_of_offers,
                  price,
                  area,
                  rooms,
                  pricemkw,
                  area_to,
                  rooms_to,
                  name,
                  investment_address,
                  investment_city,
                  files,
                  description,
                  city_name,
                  marker = null,
                },
                index
              ) => (
                <Box
                  key={"ActualOffers_" + uuid + name}
                  width="80vw"
                  mr={5}
                  ref={index === 0 ? refItem : null}
                >
                  <Investment
                    key={"ActualOffers_" + uuid + name}
                    visible_fields={{
                      offers: number_of_offers,
                      price: price,
                      area_from: area,
                      area_to: area_to,
                      rooms_from: rooms,
                      rooms_to: rooms_to,
                      uuid: uuid,
                      city: city_name ? city_name : investment_city,
                      name: name,
                      investment_address: investment_address,
                      pricemkw: pricemkw,
                      investment_district,
                      investment_uuid,
                      files,
                      description,
                      marker,
                    }}
                    backgroundColor={backgroundColor}
                    lastOfferPrimaryMarket={lastOfferPrimaryMarket}
                    lastOfferSecondaryMarket={lastOfferSecondaryMarket}
                  />
                </Box>
              )
            )}
          </Box>
          <Box display="flex" paddingX={20} justifyContent="center" mt={1}>
            {actualOffers?.map((el, index) => {
              return (
                <Box
                  key={index}
                  color="transparent"
                  width={activeSlider === index + 1 ? "30px" : "20px"}
                  height="5px"
                  mx={2}
                  borderRadius={2}
                  bgcolor={
                    activeSlider === index + 1 ? "#de1f24" : "rgba(0,0,0,0.3)"
                  }
                  onClick={() => selectSlider(index)}
                  sx={{ transition: "0.3s" }}
                />
              );
            })}
          </Box>
        </Hidden>
      </Box>
    );
  }
);
ActualOffers.displayName = "ActualOffers";

export default ActualOffers;
